.navbarSettings{
    position: absolute;
    left: 0;
    top: 75px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s linear, visibility 0s;
    padding: 30px 0;
    z-index: 50;
    padding: 15px;
    overflow-y: auto;
}

.open {
    width: 100%;
    opacity: 1;
    background-color: white;
    visibility: visible;
}

.navbarSettingsInfo{
    max-width: 1415px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.navbarSettingsTitle{
    color: #26435499;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 15px;
    line-height: 24px;
}

.itemSettigsProfile{
    max-width: 335px;
    width: 100%;
}

.navbarSettingsContainer{
    display: flex;
    gap: 25px;
    width: 100%;
}

.textSettings{
    color: #26435499;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin-bottom: 5px;
}

.inputSettings{
    color: rgba(38, 67, 84, 0.3);
    max-width: 335px;
    width: 100%;
    height: 35px;
    border-radius: 5px;
    border: 1px solid rgba(38, 67, 84, 0.15);
    outline:none;
    padding: 0 0 0 10px;
}

.inputSettings::placeholder{
    color: rgba(38, 67, 84, 0.3);
}

.navbarSettingsButton{
    background-color: rgba(119, 195, 240, 1);
    width: 215px;
    height: 38px;
    border-radius: 42.4px;
    color: rgba(247, 252, 255, 1);
    font-size: 12px;
    font-weight: 500;
    line-height: 14.22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px;
}

@media (max-width: 834px) {
    .navbarSettings{
        margin-top: 15px;
        padding-top: 0;
        height: calc(100% - 160px);
    }
    .navbarSettingsContainer{
        flex-direction: column;
        gap: 20px;
    }
    .navbarSettingsTitle{
        color: #264354CC;
        font-weight: 500;
    }
    .navbarSettingsInfo{
        gap: 20px;
        overflow-y: auto;
    }
    .itemSettigsProfile{
        max-width: 100%;
    }
    .inputSettings{
        max-width: 100%;
    }
}