.container-main {
    position: relative;
    display: flex;
    height: 370px;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 7px 22px 0px rgba(38, 67, 84, 0.1);
    overflow: hidden;
}

.container-main_small{
    width: 100%;
}

.container-main_big {
    max-width: 936px;
    width: 100%;
}
  
.img-main {
    position: absolute;
    bottom: -30px;
    right: -30px;
    opacity: 0.4;
    transition: 0.2s ease-in-out;
    z-index: -1;
}

.textLink{
    color: rgba(38, 67, 84, 0.3);
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    height: 37px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.title{
    color: rgba(38, 67, 84, 1);
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
}

@media (max-width: 834px) {
    .container-main {
        padding: 20px 10px;
        height: 300px;
    }
    .container-main_small{
        max-width: 100%;
    }
    .img-main_width{
        width: 30%;
    }
}

@media (max-width: 600px){
    .img-main_width{
        width: 50%;
    }
}