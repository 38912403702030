.tabBar{
    height: 70px;
    width: 100%;
    box-shadow: 0px 0px 13px 0px rgba(38, 67, 84, 0.1);
    align-items: center;
    justify-content: center;
    display: none;
    position: fixed;
    bottom: 0;
    background-color: white;
    z-index: 2;
}

.tabBar_container{
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 834px) {
    .tabBar{
        display: flex;
    }
}