.progress-bar {
    height: 3px;
    width: 100%;
    background-color: #BDD7E799;
    border-radius: 50px;
    position: relative;
}
  
.filler {
    height: 100%;
    border-radius: inherit;
    transition: width .2s ease-in;
}

.text-color{
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    position: absolute;
    right: -18px;
    top: -20px;
}

@media (max-width: 1010px) {
    .text-color{
        font-size: 10.78px;
        line-height: 12.94px;
        top: -15px;
    }
}