.containerLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 127px);
    width: 100%;
    position: relative;
}
  
.loaderBlue {
    position: absolute;
    animation: spin 15s linear infinite reverse;
}
  
.loaderRed {
    position: absolute;
    animation: spin 15s linear infinite;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
  
.textLoader {
    position: absolute;
    color: #73C3F3;
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
}