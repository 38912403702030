@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "Rubik";
        font-weight: 300;
        src: url("../public/fonts/Rubik-Light.ttf");
    }
    @font-face {
        font-family: "Rubik";
        font-weight: 400;
        src: url("../public/fonts/Rubik-Regular.ttf");
    }
    @font-face {
        font-family: "Rubik";
        font-weight: 500;
        src: url("../public/fonts/Rubik-Medium.ttf");
    }
    @font-face {
        font-family: "Rubik";
        font-weight: 700;
        src: url("../public/fonts/Rubik-Bold.ttf");
    }
}

@layer utilities {
    .shadow-custom {
        box-shadow: 0px 4.840425968170166px 19.361703872680664px 0px rgba(0, 0, 0, 0.12);
    }

    .blue-shadow {
        box-shadow: 0 0 15px rgba(115, 189, 233, 0.2);
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
      
    ::-webkit-scrollbar-track {
        background: white; 
    }
       
    ::-webkit-scrollbar-thumb {
        background: #96d4f8;
        cursor: pointer;
    }

    .skeleton-animated {
        @apply animate-pulse rounded-[5px] bg-slate-100;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type="range"]::-webkit-slider-thumb {
        width: 20px;
        height: 20px;
        -webkit-appearance: none;
        appearance: none;
        background: #fff;
        border: 1px solid #73bde9;
        border-radius: 100%;
    }

    input[type="range"]::-webkit-slider-thumb:hover {
        border: 1px solid #e9a573;
    }
}

#root {
    font-family: rubik;
}

.hidden-checkbox {
    display: none;
}

.custom-checkbox-white {
    min-width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 1px solid #73C3F380;
    box-shadow: -1px 2px 4px 0px #26435419;
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.hidden-checkbox:checked + .custom-checkbox-white {
    background-color: #3498db
}
  
.hidden-checkbox:checked + .custom-checkbox-white::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 7px;
    width: 5px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.radio_coral {
    display: flex;
	height: 12px;
    width: 12px;
	white-space: nowrap;
    position: relative;
}

.radio_coral input[type=radio] {
	display: none;
}

.radio_coral:last-child label{
	margin-right: 0;
}

.form_radio_coral label:before {
	content: "";
	display: inline-block;
	width: 12px;
	height: 12px;
	position: absolute;
	left: 0;
	bottom: 0;
    right: 0;
    top: 0;
	background: url(shared/ui/icon/radioEmptyCoral.svg) 0 0 no-repeat;
	background-size: contain;
    cursor: pointer;
}
 
.form_radio_coral input[type=radio]:checked + label:before {
	background: url(shared/ui/icon/radioFullCoral.svg) 0 0 no-repeat;
	background-size: contain;
}
 
.radio_coral label:hover:before {
	filter: brightness(120%);
}
