.centered{
	min-width:79px;
	height:45px;
	position: relative;
}
.blob-1,.blob-2,.blob-3{
	width:17px;
	height:17px;
	position:absolute;
	background:#fff;
	border-radius:50%;
	top:50%;left:50%;
	transform:translate(-50%,-50%);
}
.blob-1{
	left:20%;
	animation:osc-2 2.5s ease infinite;
  	background:#73C3F3;
}
.blob-2{
	left:50%;
	animation:osc-1 2.5s ease infinite;
	background:#73C3F3;
}
.blob-3{
  	left:80%;
  	background:#73C3F3;
  	animation:osc-3 2.5s ease infinite;
}
@keyframes osc-2{
	0%{left:61%;}
    25%{left:43%;}
    50%{left:25%;}
    75%{left:20%;}
	100%{left:20%;}
}
@keyframes osc-1{
	0%{left:20%;}
    25%{left:25%;}
    50%{left:43%;}
    75%{left:57%;}
	100%{left:80%;}
}
@keyframes osc-3{
	0%{left:80%;}
    25%{left:80%;}
    50%{left:80%;}
    75%{left:75%;}
	100%{left:61%;}
}