.navbarCalendar{
    position: absolute;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s linear, visibility 0s;
    padding: 30px 0;
}

.open {
    width: 100%;
    opacity: 1;
    background-color: white;
    visibility: visible;
}

.navbarCalendarInfo{
    max-width: 1415px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0 10px;
}

.navbarCalendarContainerInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.navbarCalendarTitle{
    color: #26435499;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 15px;
    padding: 0 15px;
}

.itemCalendar{
    width: 100%;
    border-bottom: 1px solid rgba(38, 67, 84, 0.15);
    padding-bottom: 10px;
}

.navbarCalendarContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 0 15px;
    max-height: 350px;
    overflow-y: auto;
}

.textCalendar{
    color:  rgba(115, 189, 233, 1);
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin-bottom: 25px;
}

.backdrop {
    position: fixed;
    top: 75px;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.5s linear, visibility 0.5s, opacity 0.5s;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    cursor: pointer;
}

.show {
    background-color: rgba(150, 212, 248, 0.3);
    visibility: visible;
    opacity: 1;
    z-index: 2;
}

.navbarCalendarContainer::-webkit-scrollbar {
    width: 5px;
}

.navbarCalendarContainer::-webkit-scrollbar-track {
    background: #FFF;
}

.navbarCalendarContainer::-webkit-scrollbar-thumb {
    background: #96d4f8;
}

@media (max-width: 834px) {
    .navbarCalendar{
        height: 80%;
        top: 85px;
    }
    .show{
        background-color: transparent
    }
}
