.navbar{
    position: absolute;
    left: 0;
    top: 75px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s linear, visibility 0s;
    z-index: 50;
}

.open {
    width: 100%;
    opacity: 1;
    background-color: white;
    visibility: visible;
}

.navbarMenuInfo{
    max-width: 1415px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0 15px;
}

.yourImage { 
    background: url(../../shared/ui/icon/Menu_block_archiveblack.svg);
    height: 32px;
    width: 36px;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-transition: background 0.5s;
    -moz-transition: background 0.5s; 
    -o-transition: background 0.5s;
    transition: background 0.5s;
}

.liMenu:hover .yourImage{ 
    background: url(../../shared/ui/icon/Menu_block_archive.svg);
    background-repeat: no-repeat;
    background-size: contain;
    height: 32px;
    width: 36px;
}

.yourImageDep { 
    background: url(../../shared/ui/icon/Menu_block_depblack.svg);
    height: 38px;
    width: 36px;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-transition: background 0.5s;
    -moz-transition: background 0.5s; 
    -o-transition: background 0.5s;
    transition: background 0.5s;
}

.liMenu:hover .yourImageDep { 
    background: url(../../shared/ui/icon/Menu_block_dep.svg);
    background-repeat: no-repeat;
    background-size: contain;
    height: 38px;
    width: 36px;
}

.yourImageSkill { 
    background: url(../../shared/ui/icon/Menu_block_skillblack.svg);
    height: 40px;
    width: 28px;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-transition: background 0.5s;
    -moz-transition: background 0.5s; 
    -o-transition: background 0.5s;
    transition: background 0.5s;
}

.liMenu:hover .yourImageSkill { 
    background: url(../../shared/ui/icon/Menu_block_skill.svg);
    background-repeat: no-repeat;
    background-size: contain;
    height: 40px;
    width: 28px;
}

.yourImageTarget { 
    background: url(../../shared/ui/icon/Menu_block_targetblack.svg);
    height: 40px;
    width: 40px;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-transition: background 0.5s;
    -moz-transition: background 0.5s; 
    -o-transition: background 0.5s;
    transition: background 0.5s;
}

.liMenu:hover .yourImageTarget { 
    background: url(../../shared/ui/icon/Menu_block_target.svg);
    background-repeat: no-repeat;
    background-size: contain;
    height: 40px;
    width: 40px;
}

.yourImageMat { 
    background: url(../../shared/ui/icon/Menu_block_matblack.svg);
    height: 34px;
    width: 36px;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-transition: background 0.5s;
    -moz-transition: background 0.5s; 
    -o-transition: background 0.5s;
    transition: background 0.5s;
}

.liMenu:hover .yourImageMat { 
    background: url(../../shared/ui/icon/Menu_block_mat.svg);
    background-repeat: no-repeat;
    background-size: contain;
    height: 34px;
    width: 36px;
}

.yourImageProfile{
    background: url(../../shared/ui/icon/profileMenu.svg);
    height: 34px;
    width: 36px;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-transition: background 0.5s;
    -moz-transition: background 0.5s; 
    -o-transition: background 0.5s;
    transition: background 0.5s;
}

.liMenu:hover .yourImageProfile{
    background: url(../../shared/ui/icon/profileMenuColor.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.yourImageIntergration{
    background: url(../../shared/ui/icon/intergrationMenu.svg);
    height: 36px;
    width: 36px;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-transition: background 0.5s;
    -moz-transition: background 0.5s; 
    -o-transition: background 0.5s;
    transition: background 0.5s;
}

.liMenu:hover .yourImageIntergration{
    background: url(../../shared/ui/icon/intergrationMenuColor.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.yourImageArchive{
    background: url(../../shared/ui/icon/archiveMenu.svg);
    height: 34px;
    width: 36px;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-transition: background 0.5s;
    -moz-transition: background 0.5s; 
    -o-transition: background 0.5s;
    transition: background 0.5s;
}

.liMenu:hover .yourImageArchive{
    background: url(../../shared/ui/icon/archiveMenuColor.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.ulMenu{
    display: grid;
    width: 100%;
    padding: 30px 0;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    gap: 20px;
    box-sizing: border-box;
    min-height: 200px;
}

.liMenu{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.textMenu{
    color: #26435499;
    transition: color 0.5s ease;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin-bottom: 2px;
}

.liMenu:hover .textMenu{
    color:#264354;
}

.linkMenu{
    color: #F37D73CC;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}

.liMenu:hover .linkMenu{
    visibility: visible;
    opacity: 1;
}

.backdrop {
    position: fixed;
    top: 75px;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.5s linear, visibility 0.5s, opacity 0.5s;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    cursor: pointer;
}

.show {
    background-color: rgba(150, 212, 248, 0.3);
    visibility: visible;
    opacity: 1;
    z-index: 2;
}

@media (max-width: 834px) {
    .navbar{
        height: 80%;
        top: 85px
    }
    .liMenu:hover .yourImageSkill{
        width: 18px;
        height: 26px;
    }
    .liMenu:hover .yourImageTarget{
        width: 24px;
        height: 25px;
    }
    .liMenu:hover .yourImageMat{
        width: 25px;
        height: 22px;
    }
    .liMenu:hover .yourImageDep{
        width: 24px;
        height: 25px;
    }
    .yourImageProfile{
        background: url(../../shared/ui/icon/profileMenuColor.svg);
        background-size: contain;
        background-repeat: no-repeat;
        width: 25px;
        height: 20px;
    }
    .yourImage{
        background: url(../../shared/ui/icon/Menu_block_archive.svg);
        width: 25px;
        height: 20px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .yourImageDep{
        background: url(../../shared/ui/icon/Menu_block_dep.svg);
        width: 24px;
        height: 25px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .yourImageSkill{
        background: url(../../shared/ui/icon/Menu_block_skill.svg);
        width: 18px;
        height: 26px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .yourImageTarget{
        background: url(../../shared/ui/icon/Menu_block_target.svg);
        width: 24px;
        height: 25px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .yourImageMat{
        background: url(../../shared/ui/icon/Menu_block_mat.svg);
        width: 25px;
        height: 22px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .yourImageArchive{
        background: url(../../shared/ui/icon/archiveMenuColor.svg);
        width: 25.96px;
        height: 22.85px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .yourImageIntergration{
        background: url(../../shared/ui/icon/intergrationMenuColor.svg);
        width: 22px;
        height: 22px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .textMenu{
        margin: 0;
        color: #264354;
    }
    .linkMenu{
        display: none;
    }
    .show{
        background-color: transparent
    }
}